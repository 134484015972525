// 检查当前域名并返回域名类型
const getDomain = () => {
  // 如果是服务器端渲染，直接返回默认值
  if (typeof window === 'undefined') {
    return 'production';
  }

  const hostname = window.location.hostname;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'localhost';
  }else if (hostname === 'dianya.leitingpro.top' || hostname === 'show.leitingpro.top') {
    return 'local';
  } else if (hostname.includes('staging') || hostname.includes('test')) {
    return 'staging';
  } else {
    return 'production';
  }
};

export default getDomain; 