import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import { StaticImage } from 'gatsby-plugin-image';
import sr from '@utils/sr';
import getDomain from '@utils/getDomain';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;



const Contact = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [showContact, setShowContact] = useState(true);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  useEffect(() => {
    // 检查域名并设置是否显示联系方式
    const domain = getDomain();
    setShowContact(domain !== 'local');
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">接下来</h2>

      <h2 className="title">联系我吧</h2>
      <p style={{
            fontSize: '16px',
            fontWeight: 100,
          }}>
        如果是我主动将这个页面推荐给你，那么我已经有信心和充足的工作时间，随时可以为您提供专业的技术支持。如果不是那也没关系，随时欢迎您，一段经验的探讨、一段代码的交流、一段思想的碰撞，有朋自远方来，不亦乐乎。
      </p>
      {!showContact && (
        <>
          <p style={{
            fontSize: '16px',
            fontWeight: 600,
          }}>
          您可以在电鸭上查看我的联系方式，也可以在电鸭上留言，我会尽快联系您。
          </p>
        </>
      )}

      {showContact && (
        <>
          {isVisible && (
            <a className="email-link" style={{
              fontSize: '16px',
              fontWeight: 100,
            }} onClick={() => setIsVisible(false)}>
              联系方式
            </a>
          )}
          {!isVisible && (
          <>
          <StaticImage
            style={{
              width: '50%',
              height: '50%',
              objectFit: 'cover',
              marginTop: '50px',
            }}
            className="img"
            src="../../images/wechat.jpg"
            width={500}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
            <p style={{
              marginTop: '10px',
              fontSize: '14px',
              fontWeight: 100,
            }}>
              微信扫一扫，添加我的微信
            </p>
            <p style={{
              marginTop: '10px',
              fontSize: '14px',
              fontWeight: 100,
            }}>
              如果您是在微信内打开此页面，可以长按二维码，选择"打开对方名片"或"识别图中二维码"，添加我的微信。
            </p>
            <p style={{
              marginTop: '10px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}>
              手机号/微信号：15236288870
            </p>
            <p style={{
              marginTop: '10px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}>
              邮箱：zhangchang_zeng@163.com
            </p>
            </>
          )}
        </>
      )}
    </StyledContactSection>
    
  );
};

export default Contact;
